@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

:root {
  /* Light theme (default) */
  --bg-color: #fafafa;
  --text-color: #2d3436;
  --card-bg: white;
  --border-color: #e1e1e1;
  --shadow-color: rgba(0, 0, 0, 0.04);
  --hover-shadow: rgba(255, 165, 0, 0.15);
  --input-bg: white;
  --input-disabled: #f3f3f3;
  --modal-overlay: rgba(0, 0, 0, 0.5);
  --notification-bg: rgba(255, 159, 26, 0.15);
  --notification-border: rgba(255, 159, 26, 0.3);
  --link-color: #ff9f1a;
  --link-hover: #f39200;
  --navbar-hover: rgba(255, 159, 26, 0.1);
}

[data-theme='dark'] {
  --bg-color: #1a1a1a;
  --text-color: #e0e0e0;
  --card-bg: #2d2d2d;
  --border-color: #404040;
  --shadow-color: rgba(0, 0, 0, 0.2);
  --hover-shadow: rgba(255, 165, 0, 0.25);
  --input-bg: #2d2d2d;
  --input-disabled: #363636;
  --modal-overlay: rgba(0, 0, 0, 0.7);
  --notification-bg: rgba(255, 159, 26, 0.1);
  --notification-border: rgba(255, 159, 26, 0.2);
  --link-color: #ffa94d;
  --link-hover: #ffb966;
  --navbar-hover: rgba(255, 159, 26, 0.2);
}

body {
  font-family: 'Inter', 'Noto Sans', sans-serif;
  padding: 20px;
  padding-top: 10px;
  width: 800px;
  margin: 0 auto;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.post,
a:hover,
.disconnect,
button,
select,
.StatusIcon,
.list-item {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='60' viewport='0 0 100 100' style='fill:black;font-size:30px;'><text y='50%'>👆</text></svg>") 16 0, auto !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
  gap: 20px;
}

#postContainer {
  width: 100%;
  box-sizing: border-box;
}

.post {
  border: none;
  padding: 20px 30px;
  margin-bottom: 25px;
  word-wrap: break-word;
  border-radius: 12px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: var(--card-bg);
  box-shadow: 0 2px 8px var(--shadow-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
}

.post:hover {
  box-shadow: 0 4px 12px var(--hover-shadow);
  transform: translateY(-2px);
}

.post h2 {
  margin-block-start: 0.4em;
}

.post-author {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;
}

.author-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

.author-name:hover {
  color: #ff9f1a;
  text-decoration: underline;
}

.post-content {
  margin-bottom: 16px;
  text-decoration: none;
  display: block;
}

.content-text {
  color: var(--text-color);
}

.post-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  margin-bottom: 0;
}

.post-time {
  font-size: 0.8rem;
  color: var(--text-color);
  opacity: 0.6;
  display: flex;
  align-items: center;
}

#navbar {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  flex-shrink: 0;
}

#navbar li {
  text-align: center;
}

#navbar li a {
  text-decoration: none;
  color: var(--text-color);
  padding: 6px 12px;
  border-radius: 6px;
  transition: background 0.2s ease;
}

#navbar li a:hover {
  background: var(--navbar-hover);
  color: var(--link-color);
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  max-width: 600px;
  box-sizing: border-box;
}

#search {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px 12px;
  transition: border-color 0.2s ease;
  margin-right: 8px;
  width: 100%;
  min-width: 0;
  background-color: var(--input-bg);
  color: var(--text-color);
}

#search:focus {
  border-color: #ff9f1a;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 159, 26, 0.1);
}

button,
#search-btn {
  background: #ff9f1a;
  border: none;
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  transition: background 0.2s ease;
  text-transform: lowercase;
}

button:disabled {
  background: grey;
  border: 1px solid grey;
  cursor: default;
}

button:hover,
#search-btn:hover {
  background: #f39200;
}

button.link {
  background: transparent;
  color: orange;
  border: none;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
}

.reply-button {
  background: rgba(255, 159, 26, 0.1);
  color: #ff9f1a;
  padding: 4px 12px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.8rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
}

.reply-button:hover {
  background: rgba(255, 159, 26, 0.2);
  transform: translateY(-1px);
}

.reply-button::after {
  content: "↩";
  font-size: 1.1em;
}

.home-link {
  text-decoration: none;
  color: var(--text-color) !important;
}

.home-link:hover {
  color: var(--link-color) !important;
  text-decoration: none;
}

a:link,
a:visited {
  color: var(--link-color);
  text-decoration: none;
}

a:hover,
a:active {
  text-decoration: underline;
}

textarea,
input {
  width: 100%;
  padding: 8px 12px;
  margin: 10px 0;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  transition: border-color 0.2s ease;
  box-sizing: border-box;
  background-color: var(--input-bg);
  color: var(--text-color);
}

input:read-only {
  background-color: var(--input-disabled);
  color: var(--text-color);
}

textarea:focus,
input:focus {
  border-color: #ff9f1a;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 159, 26, 0.1);
}

select {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 8px;
  background-color: var(--input-bg);
  color: var(--text-color);
  transition: border-color 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 8px auto;
  padding-right: 30px;
}

[data-theme='light'] select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232d3436%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

[data-theme='dark'] select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23e0e0e0%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

select:focus {
  border-color: #ff9f1a;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 159, 26, 0.1);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--modal-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background: var(--card-bg);
  padding: 24px;
  width: 600px;
  height: auto;
  max-height: 600px;
  overflow-y: auto;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.modal-title {
  font-size: 1.5rem;
  margin: 0 0 20px 0;
  color: var(--text-color);
  font-weight: 500;
}

.tab-content {
  margin-bottom: 20px;
  max-height: 450px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  padding: 4px;
}

.list-item {
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-color);
  background: var(--card-bg);
  border: 1px solid var(--border-color);
}

.list-item:hover {
  background: var(--navbar-hover);
  transform: translateY(-1px);
  box-shadow: 0 2px 8px var(--shadow-color);
}

.NotificationBar {
  width: 100%;
  height: auto;
  border-radius: 12px;
  background: var(--notification-bg);
  backdrop-filter: blur(10px);
  margin-bottom: 20px;
  color: var(--text-color);
  padding: 15px 20px;
  box-sizing: border-box;
  border: 1px solid var(--notification-border);
}

.NotificationBar a {
  color: #ff9f1a;
  text-decoration: underline;
}

.NotificationBar a:hover {
  color: #f39200;
  opacity: 1;
}

.hidden {
  display: none;
}

.submit {
  width: 100%;
  text-align: center;
}

.loading-button {
  position: relative;
  overflow: hidden;
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
  animation: loading 1s infinite linear;
}

@keyframes loading {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

@media screen and (max-width: 850px) {
  .header {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  body {
    width: 95%;
    padding: 10px;
    min-width: 320px;
  }

  .search-container {
    width: 100%;
    margin: 10px 0;
  }

  .post {
    padding: 15px 20px;
  }

  button,
  #search-btn {
    padding: 8px 12px;
    white-space: nowrap;
  }
}

.logo {
  flex-shrink: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}

.header h1 {
  margin: 0;
  font-size: 1.8rem;
  white-space: nowrap;
  flex-shrink: 0;
}

#addPost {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  background: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 2px 8px var(--shadow-color);
}

@media screen and (max-width: 850px) {
  #addPost {
    padding: 15px 20px;
  }
  
  body {
    width: 95%;
    padding: 10px;
    min-width: 320px;
  }
}

/* Tab styles for the name selection modal */
.tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}

.tabs button {
  background: none;
  border: none;
  color: var(--text-color);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-transform: lowercase;
}

.tabs button:hover {
  background: var(--navbar-hover);
}

.tabs button.active {
  background: var(--navbar-hover);
  color: var(--link-color);
}

/* Close button at the bottom */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 20px;
}

.close-button:hover {
  background: var(--navbar-hover);
  color: var(--link-color);
}