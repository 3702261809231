#nprogress .bar {
  background: linear-gradient(90deg, var(--link-color), #ffd700);
  height: 3px;
  box-shadow: 0 0 10px rgba(255, 159, 26, 0.5);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
}

#nprogress .peg {
  display: none;
} 